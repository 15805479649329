<template>
  <div
    class="sensitivity-testing px-8 py-10 container"
    style="max-width: 1150px;margin:0 auto;"
  >
    <div class="flex flex-wrap">
      <div class="w-full md:w-1/2 mb-6 md:pr-2">
        <img
          src="@/assets/Testing/MBOM-Impact-Sample-Application-min.jpg"
          width="500"
          height="320"
          alt=""
          title="Explosives Sensitivity Testing"
          class="pb-4"
        />
        <TitleComponent title="Tests Available" />
        <TabsComponent :tabs="tabs" />
      </div>
      <div class="w-full md:w-1/2 mb-6 md:pl-2">
        <TitleComponent title="Sensitivity Testing" size="large" />
        <Paragraph
          ><p>
            Safety Management Services, Inc. (SMS) has extensive testing
            capabilities for characterization of explosives and hazardous
            materials. SMS personnel are intimately familiar with test
            methodologies, equipment, data acquisition, and data evaluation.
          </p>
          <p>
            Our understanding of explosives and hazardous materials along with
            our testing expertise allows us to assist clients with determining
            what tests are appropriate, development of custom tests,
            interpretation of test results, and application of test results to
            the specific process or system. These testing services may be used
            independently or in conjunction with our
            <router-link
              class="link"
              title="PHA"
              to="/risk-management/process-hazards-analysis"
              >process hazards analysis</router-link
            >,
            <router-link
              class="link"
              title="Incident Investigation"
              to="/risk-management/incident-investigation"
              >incident investigation</router-link
            >,
            <router-link
              class="link"
              title="Facility Siting"
              to="/risk-management/facility-siting-and-design"
              >facility siting</router-link
            >, and other analysis services. Additionally, SMS offers
            <router-link class="link" title="Personnel Training" to="/training"
              >training courses</router-link
            >
            on the fundamental techniques used in explosives testing.
          </p>
          <p>
            SMS testing capabilities include laboratory, bench-scale, sub-scale,
            and full-scale testing. This includes over 70 standard tests are
            available to characterize materials under conditions that simulate
            actual process conditions. Testing capabilities include both
            standard and custom testing tailored specifically to the client’s
            needs.
          </p>
          <p>
            These standard tests include material sensitivity testing (e.g.,
            impact, friction, ESD, electrical properties, chemical
            compatibility, etc.), DOT and DoD testing, and TNT equivalency
            testing. Our testing capabilities also include custom testing
            tailored specifically to the client’s requirements. Bench-scale and
            full-scale testing can be performed to better understand key
            operating parameters of processes and systems. Recommendations based
            on
            <router-link
              class="link"
              title="Statistical Evaluation"
              to="/resources/statistical-evaluation"
              >statistical evaluation</router-link
            >
            of the material test data are made to help the client minimize
            process risks.
          </p>
          <p>
            Below is a list of many of our test capabilities. A more in-depth
            review of various tests and methods can be found by clicking the
            links at the bottom of this page (some familiarity with explosives
            and related testing is assumed). For testing consideration, SMS must
            be provided with information needed to understand your material and
            the objective of the testing, as specified in
            <router-link
              class="link"
              title="Library"
              to="/resources/document-library"
              >“Standard Required Information for Testing.”</router-link
            >
          </p>
        </Paragraph>
      </div>
    </div>
  </div>
</template>

<script>
import TitleComponent from "@/components/TitleComponent";
import Paragraph from "@/components/Paragraph";
import TabsComponent from "@/components/TabsComponent";

export default {
  metaInfo: {
    title: "Material Sensitivity Testing",
    titleTemplate: `%s | Safety Management Services, Inc.`,
    meta: [
      {
        name: "description",
        content:
          "SMS has extensive experience in performing material sensitivity testing, this includes impact, friction, ESD, electrical properties, chemical compatibility testing."
      }
    ]
  },
  components: {
    TitleComponent,
    Paragraph,
    TabsComponent
  },
  data: () => {
    return {
      tabs: [
        {
          html: true,
          name: "Material Sensitivity",
          content: `<ul>
<li>Electrostatic Discharge (ESD)</li>
<li>Friction</li>
<li>Impact</li>
<li>Probit Analysis</li>
</ul>`
        },
        {
          html: true,
          name: "Thermal & Compatibility",
          content: `<ul>
<li>Differential Scanning Calorimeter Tests</li>
<li>Fisher-Johns Auto-Ignition</li>
<li>Henkin Bath</li>
<li>Modified Taliani</li>
<li>Simulated Bulk Auto-Ignition Temperature (SBAT)</li>
<li>Taliani Stability Test</li>
<li>Thermal Stability</li>
<li>Vacuum Stability</li>
<li>Woods Metal Bath</li>
</ul>`
        },
        {
          html: true,
          name: "Electrical Properties",
          content: `<ul>
<li>Airvey Charge Generation</li>
<li>Dielectric Breakdown Strength</li>
<li>French (ESD) Test</li>
<li>Triboelectric Charging and Sliding Charge Generation</li>
<li>Volume and Surface Resistivity and Dielectric Constant</li>
</ul>`
        },
        {
          html: true,
          name: "Dust Explosibility",
          content: `<ul>
<li>Explosibility Index</li>
<li>Harman Minimum Concentration and Energy</li>
<li>Kst Value of Combustible Dusts</li>
<li>20-Liter Dust Bomb</li>
<li>20-Liter Pressure Rate</li>
</ul>`
        },
        {
          html: true,
          name: "Vapor Explosibility",
          content: `<ul>
<li>Parr Bombs</li>
<li>UFL/LFL (gases and liquids)</li>
</ul>`
        },
        {
          html: true,
          name: "Large Scale Tests",
          content: `<ul>
<li>Custom Tests and Test Design</li>
<li>Experimental Machining</li>
<li>In-process Simulations</li>
<li>Large Scale Critical Diameter</li>
<li>Large Scale Critical Height</li>
<li>Separation Ring Coupon</li>
<li>Skid Tests</li>
<li>TNT Equivalency</li>
</ul>`
        },
        {
          html: true,
          name: "Reactivity",
          content: `<ul>
<li>Explosive Cap Testing (No. 8 Cap)</li>
<li>Hazardous When Wet</li>
<li>Ignition and Unconfined Burn</li>
<li>Internal Ignition</li>
<li>Modified Unconfined Burn-Oxidizers</li>
<li>Single Package</li>
<li>Zero Gap, Card Gap</li>
</ul>`
        },
        {
          html: true,
          name: "Insensitive Munitions",
          content: `<ul>
<li>Cook-off</li>
<li>Fast Cook-off, Jet Fuel or Propane</li>
<li>Shaped Charge Jet</li>
<li>Shaped Charge Spall</li>
<li>Slow Cook-off</li>
<li>Sympathetic Detonation</li>
</ul>`
        },
        {
          html: true,
          name: "Storage Classification Tests (BATF)",
          content: `<ul>
<li>High Explosive</li>
<li>Low Explosive</li>
</ul>`
        },
        {
          html: true,
          name: "Other Tests",
          content: `<ul>
<li>Bonfire</li>
<li>Bullet Impact</li>
<li>Closed Bomb</li>
<li>Closed Pipe</li>
<li>Critical Diameter Standard</li>
<li>Critical Diameter Wedge</li>
<li>Critical Height</li>
<li>Deflagration to Detonation</li>
<li>Detonation Velocity</li>
<li>Drop Test</li>
<li>EIDS Gap</li>
<li>EPA Office of Solid Wast Test Method 1030</li>
<li>Free Fall Impingement</li>
<li>Gas Permeation Chromatography (GPC)</li>
<li>Heat of Combustion</li>
<li>Heat of Reaction</li>
<li>Koenen</li>
<li>CO2, CO, NOx Gas Analysis from Sensitivity Tests</li>
<li>Liquid Impingement</li>
<li>Low Level Shock/Low Energy Mechanical Shock</li>
<li>Mass Burn Rate</li>
<li>Modified UN screening Burn Rate Test</li>
<li>NOL Card Gap</li>
<li>Oxidizing Liquids Test</li>
<li>N.1 Readily Combustible Solids</li>
<li>Self-Accelerating Decomposition Temperature (SADT)</li>
<li>70 mm Gun, 25 mm Gun, Shotgun</li>
<li>Solid Impingement</li>
<li>Thin Layer Burning</li>
<li>Thin Layer Propagation</li>
<li>Time/Pressure</li>
<li>Transparent Pipe</li>
<li>UN Gap</li>
</ul>`
        }
      ]
    };
  }
};
</script>

<style lang="scss">
.sensitivity-testing {
  .link {
    color: #aa222c;
  }
}
</style>
